*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

html {
  -webkit-tap-highlight-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  height: 100%;
}

@media (prefers-color-scheme: dark) {
  html {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  }
}

.coins, html.coins {
  --tw-bg-opacity: 1;
  background-color: rgb(231 229 228 / var(--tw-bg-opacity));
  background-image: url("/bg.00000002.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.coins:after, html.coins:after {
  z-index: -10;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  content: var(--tw-content);
  --tw-bg-opacity: .8;
  position: fixed;
  inset: 0;
}

@media (prefers-color-scheme: dark) {
  .coins, html.coins {
    --tw-bg-opacity: 1;
    background-color: rgb(41 37 36 / var(--tw-bg-opacity));
  }

  .coins:after, html.coins:after {
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    content: var(--tw-content);
    --tw-bg-opacity: .8;
  }
}

body {
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity));
  min-height: 100%;
}

@media (prefers-color-scheme: dark) {
  body {
    --tw-text-opacity: 1;
    color: rgb(212 212 216 / var(--tw-text-opacity));
  }
}

body.scroll-lock {
  overflow: hidden;
}

html.app > body {
  height: 100%;
}

body.centered {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

:focus-visible {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

input[type="search"]::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

input[type="search"]::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

:is(input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration) {
  display: none;
}

div[style^="position: fixed; bottom: 2em; right: 2em;"] {
  bottom: 4rem !important;
  right: .5rem !important;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(strong):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"] *)), .prose :where(blockquote strong):not(:where([class~="not-prose"] *)), .prose :where(thead th strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(hr):not(:where([class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose :where(code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"] *)), .prose :where(h1 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"] *)), .prose :where(blockquote code):not(:where([class~="not-prose"] *)), .prose :where(thead th code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"] *)) {
  table-layout: auto;
  text-align: left;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"] *)) {
  vertical-align: top;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(video):not(:where([class~="not-prose"] *)), .prose :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *)), .prose :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(hr + *):not(:where([class~="not-prose"] *)), .prose :where(h2 + *):not(:where([class~="not-prose"] *)), .prose :where(h3 + *):not(:where([class~="not-prose"] *)), .prose :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
  padding: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose-zinc {
  --tw-prose-body: #3f3f46;
  --tw-prose-headings: #18181b;
  --tw-prose-lead: #52525b;
  --tw-prose-links: #18181b;
  --tw-prose-bold: #18181b;
  --tw-prose-counters: #71717a;
  --tw-prose-bullets: #d4d4d8;
  --tw-prose-hr: #e4e4e7;
  --tw-prose-quotes: #18181b;
  --tw-prose-quote-borders: #e4e4e7;
  --tw-prose-captions: #71717a;
  --tw-prose-code: #18181b;
  --tw-prose-pre-code: #e4e4e7;
  --tw-prose-pre-bg: #27272a;
  --tw-prose-th-borders: #d4d4d8;
  --tw-prose-td-borders: #e4e4e7;
  --tw-prose-invert-body: #d4d4d8;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #a1a1aa;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #a1a1aa;
  --tw-prose-invert-bullets: #52525b;
  --tw-prose-invert-hr: #3f3f46;
  --tw-prose-invert-quotes: #f4f4f5;
  --tw-prose-invert-quote-borders: #3f3f46;
  --tw-prose-invert-captions: #a1a1aa;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d4d4d8;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #52525b;
  --tw-prose-invert-td-borders: #3f3f46;
}

.btn {
  text-transform: uppercase;
  border-radius: .5rem;
  padding: .75rem 1.5rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.btn:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.btn-small {
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.btn-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 126 126 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-style: none;
}

.btn-primary:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(0 153 153 / var(--tw-bg-opacity));
}

.btn-secondary {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(0 126 126 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 126 126 / var(--tw-text-opacity));
  padding-top: calc(.75rem - 4px);
  padding-bottom: calc(.75rem - 4px);
}

.btn-secondary:hover:enabled {
  --tw-border-opacity: 1;
  border-color: rgb(0 153 153 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 153 153 / var(--tw-text-opacity));
}

@media (prefers-color-scheme: dark) {
  .btn-secondary {
    --tw-border-opacity: 1;
    border-color: rgb(0 153 153 / var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgb(0 153 153 / var(--tw-text-opacity));
  }

  .btn-secondary:hover:enabled {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 216 / var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgb(212 212 216 / var(--tw-text-opacity));
  }
}

.btn-tertiary {
  --tw-text-opacity: 1;
  color: rgb(0 126 126 / var(--tw-text-opacity));
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.btn-tertiary:hover:enabled {
  --tw-text-opacity: 1;
  color: rgb(0 153 153 / var(--tw-text-opacity));
}

.btn-tertiary {
  padding: .5rem 0;
}

@media (prefers-color-scheme: dark) {
  .btn-tertiary {
    --tw-text-opacity: 1;
    color: rgb(0 153 153 / var(--tw-text-opacity));
  }

  .btn-tertiary:hover:enabled {
    --tw-text-opacity: 1;
    color: rgb(212 212 216 / var(--tw-text-opacity));
  }
}

.box {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .75rem;
  width: 32rem;
  max-width: 90%;
  margin: 1.5rem 1.5rem 4rem;
  padding: 1.5rem;
}

@media (prefers-color-scheme: dark) {
  .box {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  }
}

.box-title {
  --tw-text-opacity: 1;
  color: rgb(0 126 126 / var(--tw-text-opacity));
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@media (prefers-color-scheme: dark) {
  .box-title {
    --tw-text-opacity: 1;
    color: rgb(0 153 153 / var(--tw-text-opacity));
  }
}

.text-field {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(228 228 231 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
  border-radius: .5rem;
  width: 100%;
  margin-top: .25rem;
  padding: .75rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-field::placeholder {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
}

.text-field:disabled {
  opacity: .5;
}

@media (prefers-color-scheme: dark) {
  .text-field {
    --tw-border-opacity: 1;
    border-color: rgb(63 63 70 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(39 39 42 / var(--tw-bg-opacity));
  }

  .text-field::placeholder {
    --tw-text-opacity: 1;
    color: rgb(113 113 122 / var(--tw-text-opacity));
  }
}

.grid-lg, .grid-md, .grid-sm {
  flex-flow: wrap;
  display: flex;
}

.grid-lg .grid-item {
  width: 100%;
}

@media (min-width: 256px) {
  .grid-lg .grid-item {
    width: 50%;
  }
}

@media (min-width: 512px) {
  .grid-lg .grid-item {
    width: 33.3333%;
  }
}

@media (min-width: 768px) {
  .grid-lg .grid-item {
    width: 25%;
  }
}

@media (min-width: 1024px) {
  .grid-lg .grid-item {
    width: 20%;
  }
}

@media (min-width: 1280px) {
  .grid-lg .grid-item {
    width: 16.6667%;
  }
}

@media (min-width: 1536px) {
  .grid-lg .grid-item {
    width: 14.2857%;
  }
}

@media (min-width: 1792px) {
  .grid-lg .grid-item {
    width: 12.5%;
  }
}

@media (min-width: 2048px) {
  .grid-lg .grid-item {
    width: 11.1111%;
  }
}

.grid-lg .grid-item {
  padding: 4vw;
}

@media (min-width: 256px) {
  .grid-lg .grid-item {
    padding: 2vw;
  }
}

@media (min-width: 512px) {
  .grid-lg .grid-item {
    padding: 1.3333vw;
  }
}

@media (min-width: 768px) {
  .grid-lg .grid-item {
    padding: 1vw;
  }
}

@media (min-width: 1024px) {
  .grid-lg .grid-item {
    padding: .8vw;
  }
}

@media (min-width: 1280px) {
  .grid-lg .grid-item {
    padding: .6667vw;
  }
}

@media (min-width: 1536px) {
  .grid-lg .grid-item {
    padding: .5714vw;
  }
}

@media (min-width: 1792px) {
  .grid-lg .grid-item {
    padding: .5vw;
  }
}

@media (min-width: 2048px) {
  .grid-lg .grid-item {
    padding: .4444vw;
  }
}

.grid-md .grid-item {
  width: 50%;
}

@media (min-width: 256px) {
  .grid-md .grid-item {
    width: 33.3333%;
  }
}

@media (min-width: 512px) {
  .grid-md .grid-item {
    width: 25%;
  }
}

@media (min-width: 768px) {
  .grid-md .grid-item {
    width: 20%;
  }
}

@media (min-width: 1024px) {
  .grid-md .grid-item {
    width: 16.6667%;
  }
}

@media (min-width: 1280px) {
  .grid-md .grid-item {
    width: 14.2857%;
  }
}

@media (min-width: 1536px) {
  .grid-md .grid-item {
    width: 12.5%;
  }
}

@media (min-width: 1792px) {
  .grid-md .grid-item {
    width: 11.1111%;
  }
}

@media (min-width: 2048px) {
  .grid-md .grid-item {
    width: 10%;
  }
}

.grid-md .grid-item {
  padding: 2vw;
}

@media (min-width: 256px) {
  .grid-md .grid-item {
    padding: 1.3333vw;
  }
}

@media (min-width: 512px) {
  .grid-md .grid-item {
    padding: 1vw;
  }
}

@media (min-width: 768px) {
  .grid-md .grid-item {
    padding: .8vw;
  }
}

@media (min-width: 1024px) {
  .grid-md .grid-item {
    padding: .6667vw;
  }
}

@media (min-width: 1280px) {
  .grid-md .grid-item {
    padding: .5714vw;
  }
}

@media (min-width: 1536px) {
  .grid-md .grid-item {
    padding: .5vw;
  }
}

@media (min-width: 1792px) {
  .grid-md .grid-item {
    padding: .4444vw;
  }
}

@media (min-width: 2048px) {
  .grid-md .grid-item {
    padding: .4vw;
  }
}

.grid-sm .grid-item {
  width: 33.3333%;
}

@media (min-width: 256px) {
  .grid-sm .grid-item {
    width: 25%;
  }
}

@media (min-width: 512px) {
  .grid-sm .grid-item {
    width: 20%;
  }
}

@media (min-width: 768px) {
  .grid-sm .grid-item {
    width: 16.6667%;
  }
}

@media (min-width: 1024px) {
  .grid-sm .grid-item {
    width: 14.2857%;
  }
}

@media (min-width: 1280px) {
  .grid-sm .grid-item {
    width: 12.5%;
  }
}

@media (min-width: 1536px) {
  .grid-sm .grid-item {
    width: 11.1111%;
  }
}

@media (min-width: 1792px) {
  .grid-sm .grid-item {
    width: 10%;
  }
}

@media (min-width: 2048px) {
  .grid-sm .grid-item {
    width: 9.09091%;
  }
}

.grid-sm .grid-item {
  padding: 1.3333vw;
}

@media (min-width: 256px) {
  .grid-sm .grid-item {
    padding: 1vw;
  }
}

@media (min-width: 512px) {
  .grid-sm .grid-item {
    padding: .8vw;
  }
}

@media (min-width: 768px) {
  .grid-sm .grid-item {
    padding: .6667vw;
  }
}

@media (min-width: 1024px) {
  .grid-sm .grid-item {
    padding: .5714vw;
  }
}

@media (min-width: 1280px) {
  .grid-sm .grid-item {
    padding: .5vw;
  }
}

@media (min-width: 1536px) {
  .grid-sm .grid-item {
    padding: .4444vw;
  }
}

@media (min-width: 1792px) {
  .grid-sm .grid-item {
    padding: .4vw;
  }
}

@media (min-width: 2048px) {
  .grid-sm .grid-item {
    padding: .3636vw;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.-right-1 {
  right: -.25rem;
}

.-right-2 {
  right: -.5rem;
}

.-right-3 {
  right: -.75rem;
}

.-top-2 {
  top: -.5rem;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.right-\[1\.125rem\] {
  right: 1.125rem;
}

.top-0 {
  top: 0;
}

.top-3 {
  top: .75rem;
}

.top-\[4\.25rem\] {
  top: 4.25rem;
}

.\!z-0 {
  z-index: 0 !important;
}

.-z-\[1\] {
  z-index: -1;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.m-\[0\.2rem\] {
  margin: .2rem;
}

.m-auto {
  margin: auto;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-2\.5 {
  margin-top: .625rem;
  margin-bottom: .625rem;
}

.-mb-14 {
  margin-bottom: -3.5rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.-ml-2\.5 {
  margin-left: -.625rem;
}

.-mr-0 {
  margin-right: 0;
}

.-mr-0\.5 {
  margin-right: -.125rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.\!block {
  display: block !important;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
}

.table-row {
  display: table-row;
}

.grid {
  display: grid;
}

.\!hidden {
  display: none !important;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-full {
  height: 100%;
}

.max-h-\[calc\(85vh-85px\)\] {
  max-height: calc(85vh - 85px);
}

.min-h-full {
  min-height: 100%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-3\/4 {
  width: 75%;
}

.w-44 {
  width: 11rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-\[22rem\] {
  width: 22rem;
}

.w-\[48rem\] {
  width: 48rem;
}

.w-full {
  width: 100%;
}

.min-w-\[1\.25rem\] {
  min-width: 1.25rem;
}

.min-w-\[9rem\] {
  min-width: 9rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-\[75\%\] {
  max-width: 75%;
}

.max-w-\[calc\(100\%-30px\)\] {
  max-width: calc(100% - 30px);
}

.max-w-\[calc\(100vw-30px\)\] {
  max-width: calc(100vw - 30px);
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.table-auto {
  table-layout: auto;
}

.origin-left {
  transform-origin: 0;
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-12 {
  --tw-rotate: 12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[0\.85\] {
  --tw-scale-x: .85;
  --tw-scale-y: .85;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.125rem * var(--tw-space-x-reverse));
  margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-all {
  word-break: break-all;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-current {
  border-color: currentColor;
}

.border-mint {
  --tw-border-opacity: 1;
  border-color: rgb(0 153 153 / var(--tw-border-opacity));
}

.border-mint-dark {
  --tw-border-opacity: 1;
  border-color: rgb(0 126 126 / var(--tw-border-opacity));
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-zinc-200 {
  --tw-border-opacity: 1;
  border-color: rgb(228 228 231 / var(--tw-border-opacity));
}

.border-zinc-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 216 / var(--tw-border-opacity));
}

.border-t-zinc-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(228 228 231 / var(--tw-border-opacity));
}

.border-opacity-50 {
  --tw-border-opacity: .5;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-mint {
  --tw-bg-opacity: 1;
  background-color: rgb(0 153 153 / var(--tw-bg-opacity));
}

.bg-mint-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(0 126 126 / var(--tw-bg-opacity));
}

.bg-mint-light {
  --tw-bg-opacity: 1;
  background-color: rgb(200 225 225 / var(--tw-bg-opacity));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-zinc-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
}

.bg-zinc-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(228 228 231 / var(--tw-bg-opacity));
}

.bg-zinc-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(161 161 170 / var(--tw-bg-opacity));
}

.bg-zinc-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-\[4\.5rem\] {
  padding-top: 4.5rem;
}

.pt-\[5rem\] {
  padding-top: 5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.align-sub {
  vertical-align: sub;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.leading-snug {
  line-height: 1.375;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-mint {
  --tw-text-opacity: 1;
  color: rgb(0 153 153 / var(--tw-text-opacity));
}

.text-mint-dark {
  --tw-text-opacity: 1;
  color: rgb(0 126 126 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-zinc-700 {
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity));
}

.text-opacity-70 {
  --tw-text-opacity: .7;
}

.underline {
  text-decoration-line: underline;
}

.\!opacity-0 {
  opacity: 0 !important;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-700 {
  transition-duration: .7s;
}

.bg-img-logo {
  background-image: url("numistar-logo.fe7a0963.svg");
}

.bg-price-label {
  background-image: url("price-label.2ebf2ac1.svg");
}

.\[backface-visibility\:hidden\] {
  backface-visibility: hidden;
}

.\[perspective\:750px\] {
  perspective: 750px;
}

.\[transform-style\:preserve-3d\] {
  transform-style: preserve-3d;
}

.\[transform\:rotateX\(-180deg\)\] {
  transform: rotateX(-180deg);
}

.\[transform\:rotateX\(0deg\)\] {
  transform: rotateX(0);
}

.\[transform\:rotateX\(180deg\)\] {
  transform: rotateX(180deg);
}

.\[transform\:rotateY\(-180deg\)\] {
  transform: rotateY(-180deg);
}

.\[transform\:rotateY\(0deg\)\] {
  transform: rotateY(0);
}

.\[transform\:rotateY\(180deg\)\] {
  transform: rotateY(180deg);
}

@media (prefers-color-scheme: dark) {
  .dark\:prose-invert {
    --tw-prose-body: var(--tw-prose-invert-body);
    --tw-prose-headings: var(--tw-prose-invert-headings);
    --tw-prose-lead: var(--tw-prose-invert-lead);
    --tw-prose-links: var(--tw-prose-invert-links);
    --tw-prose-bold: var(--tw-prose-invert-bold);
    --tw-prose-counters: var(--tw-prose-invert-counters);
    --tw-prose-bullets: var(--tw-prose-invert-bullets);
    --tw-prose-hr: var(--tw-prose-invert-hr);
    --tw-prose-quotes: var(--tw-prose-invert-quotes);
    --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
    --tw-prose-captions: var(--tw-prose-invert-captions);
    --tw-prose-code: var(--tw-prose-invert-code);
    --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
    --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
    --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
    --tw-prose-td-borders: var(--tw-prose-invert-td-borders);
  }
}

.placeholder\:text-transparent::placeholder {
  color: #0000;
}

.placeholder\:text-zinc-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
}

.after\:z-0:after {
  content: var(--tw-content);
  z-index: 0;
}

.open\:z-30[open] {
  z-index: 30;
}

.open\:opacity-100[open] {
  opacity: 1;
}

.placeholder-shown\:pr-1:placeholder-shown {
  padding-right: .25rem;
}

.placeholder-shown\:pr-1\.5:placeholder-shown {
  padding-right: .375rem;
}

.hover\:border-zinc-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 216 / var(--tw-border-opacity));
}

.hover\:bg-mint-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(200 225 225 / var(--tw-bg-opacity));
}

.hover\:text-mint:hover {
  --tw-text-opacity: 1;
  color: rgb(0 153 153 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.enabled\:hover\:text-red-500:hover:enabled {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.disabled\:hover\:text-mint-dark:hover:disabled {
  --tw-text-opacity: 1;
  color: rgb(0 126 126 / var(--tw-text-opacity));
}

.group:hover .group-hover\:border-mint {
  --tw-border-opacity: 1;
  border-color: rgb(0 153 153 / var(--tw-border-opacity));
}

.group:hover .group-hover\:border-zinc-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 216 / var(--tw-border-opacity));
}

.group:hover .group-hover\:bg-mint {
  --tw-bg-opacity: 1;
  background-color: rgb(0 153 153 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:\[transform\:rotateX\(-180deg\)\] {
  transform: rotateX(-180deg);
}

.group:hover .group-hover\:\[transform\:rotateX\(0deg\)\] {
  transform: rotateX(0);
}

.group:hover .group-hover\:\[transform\:rotateY\(-180deg\)\] {
  transform: rotateY(-180deg);
}

.group:hover .group-hover\:\[transform\:rotateY\(0deg\)\] {
  transform: rotateY(0);
}

.peer:checked ~ .peer-checked\:bg-mint-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(0 126 126 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:hidden {
  display: none;
}

.peer:focus-visible ~ .peer-focus-visible\:ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.peer:focus-visible ~ .peer-focus-visible\:ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

@media (prefers-color-scheme: dark) {
  .dark\:border-t-0 {
    border-top-width: 0;
  }

  .dark\:border-mint {
    --tw-border-opacity: 1;
    border-color: rgb(0 153 153 / var(--tw-border-opacity));
  }

  .dark\:border-zinc-700 {
    --tw-border-opacity: 1;
    border-color: rgb(63 63 70 / var(--tw-border-opacity));
  }

  .dark\:border-zinc-800 {
    --tw-border-opacity: 1;
    border-color: rgb(39 39 42 / var(--tw-border-opacity));
  }

  .dark\:border-t-zinc-800 {
    --tw-border-opacity: 1;
    border-top-color: rgb(39 39 42 / var(--tw-border-opacity));
  }

  .dark\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .dark\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  }

  .dark\:bg-mint {
    --tw-bg-opacity: 1;
    background-color: rgb(0 153 153 / var(--tw-bg-opacity));
  }

  .dark\:bg-mint-darker {
    --tw-bg-opacity: 1;
    background-color: rgb(0 55 55 / var(--tw-bg-opacity));
  }

  .dark\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .dark\:bg-zinc-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(113 113 122 / var(--tw-bg-opacity));
  }

  .dark\:bg-zinc-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(63 63 70 / var(--tw-bg-opacity));
  }

  .dark\:bg-zinc-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(39 39 42 / var(--tw-bg-opacity));
  }

  .dark\:bg-zinc-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(24 24 27 / var(--tw-bg-opacity));
  }

  .dark\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .dark\:text-mint {
    --tw-text-opacity: 1;
    color: rgb(0 153 153 / var(--tw-text-opacity));
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dark\:text-zinc-300 {
    --tw-text-opacity: 1;
    color: rgb(212 212 216 / var(--tw-text-opacity));
  }

  .dark\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .dark\:opacity-50 {
    opacity: .5;
  }

  .dark\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
  }

  .dark\:\[text-shadow\:1px_1px_0_\#0003\] {
    text-shadow: 1px 1px #0003;
  }

  .dark\:placeholder\:text-zinc-500::placeholder {
    --tw-text-opacity: 1;
    color: rgb(113 113 122 / var(--tw-text-opacity));
  }

  .dark\:hover\:border-zinc-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(63 63 70 / var(--tw-border-opacity));
  }

  .dark\:hover\:bg-mint-darker:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(0 55 55 / var(--tw-bg-opacity));
  }

  .dark\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dark\:disabled\:hover\:text-mint:hover:disabled {
    --tw-text-opacity: 1;
    color: rgb(0 153 153 / var(--tw-text-opacity));
  }

  .group:hover .dark\:group-hover\:border-zinc-700 {
    --tw-border-opacity: 1;
    border-color: rgb(63 63 70 / var(--tw-border-opacity));
  }

  .peer:checked ~ .dark\:peer-checked\:bg-mint {
    --tw-bg-opacity: 1;
    background-color: rgb(0 153 153 / var(--tw-bg-opacity));
  }
}

@media (min-width: 640px) {
  .sm\:float-right {
    float: right;
  }

  .sm\:mb-12 {
    margin-bottom: 3rem;
  }

  .sm\:ml-12 {
    margin-left: 3rem;
  }

  .sm\:ml-3 {
    margin-left: .75rem;
  }

  .sm\:mr-3 {
    margin-right: .75rem;
  }

  .sm\:mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:mt-\[3\.20rem\] {
    margin-top: 3.2rem;
  }

  .sm\:w-\[4\.5rem\] {
    width: 4.5rem;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:text-zinc-700 {
    --tw-text-opacity: 1;
    color: rgb(63 63 70 / var(--tw-text-opacity));
  }

  .sm\:text-opacity-40 {
    --tw-text-opacity: .4;
  }
}

@media (prefers-color-scheme: dark) {
  @media (min-width: 640px) {
    .dark\:sm\:text-zinc-300 {
      --tw-text-opacity: 1;
      color: rgb(212 212 216 / var(--tw-text-opacity));
    }

    .dark\:sm\:text-opacity-40 {
      --tw-text-opacity: .4;
    }
  }
}

@media (min-width: 768px) {
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

/*# sourceMappingURL=404.c0307c48.css.map */
