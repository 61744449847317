@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    @apply h-full bg-white dark:bg-black;
  }

  .coins, /* used for 404 page in Elm */
  html.coins {
    @apply bg-coins-photo bg-stone-200 bg-center bg-fixed bg-cover;
    @apply after:inset-0 after:fixed after:bg-white after:bg-opacity-80 after:-z-10;
    @apply dark:bg-stone-800 dark:after:bg-black dark:after:bg-opacity-80;
  }

  body {
    @apply min-h-full text-zinc-700 dark:text-zinc-300;
  }

  body.scroll-lock {
    /* doesn't work for iOS, but who cares ;P */
    @apply overflow-hidden;
  }

  /* Elm removes class attribute on <body>, therefore applying it via <html> */
  html.app > body {
    @apply h-full;
  }

  body.centered {
    @apply flex justify-center items-center flex-col;
  }

  :focus-visible {
    @apply outline-none ring ring-blue-300;
  }

  /* https://geektnt.com/how-to-remove-x-from-search-input-field-on-chrome-and-ie.html */
  input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  /* modify Elm debug button */
  div[style^="position: fixed; bottom: 2em; right: 2em;"] {
    bottom: 4rem !important;
    right: 0.5rem !important;
  }
}

@layer utilities {
  .glow-white {
    box-shadow: 0 0 3rem 3rem rgba(255, 255, 255, 0.75);
  }
  .glow-black {
    box-shadow: 0 0 3rem 3rem rgba(0, 0, 0, 0.75);
  }
  .bg-img-logo {
    background-image: url("./images/numistar-logo.svg");
  }
  .bg-price-label {
    background-image: url("./images/price-label.svg");
  }
}

@layer components {
  .text-link {
    @apply text-mint-dark underline;
    @apply dark:text-mint;
  }

  .btn {
    @apply rounded-lg inline-block uppercase text-base font-bold py-3 px-6;
    @apply transition-colors duration-200;
    @apply disabled:opacity-50 disabled:cursor-not-allowed;
  }
  .btn-small {
    @apply text-sm py-2 px-4;
  }
  .btn-primary {
    @apply bg-mint-dark border-none text-white;
    @apply enabled:hover:bg-mint;
  }
  .btn-secondary {
    @apply border-2 text-mint-dark border-mint-dark py-[calc(.75rem-4px)];
    @apply enabled:hover:border-mint enabled:hover:text-mint;
    @apply dark:border-mint dark:text-mint;
    @apply dark:enabled:hover:border-zinc-300 dark:enabled:hover:text-zinc-300;
  }
  .btn-tertiary {
    @apply text-mint-dark py-[calc(.75rem)] enabled:hover:text-mint;
    @apply dark:text-mint dark:enabled:hover:text-zinc-300 px-0 py-2;
  }

  .box {
    @apply shadow-lg p-6 m-6 mb-16 bg-white rounded-xl w-[32rem] max-w-[90%];
    @apply dark:bg-black;
  }

  .box-title {
    @apply text-mint-dark text-3xl;
    @apply dark:text-mint;
  }

  .text-field {
    @apply mt-1 rounded-lg py-3 px-4 text-base border border-zinc-200;
    @apply bg-zinc-100 placeholder:text-zinc-400 w-full disabled:opacity-50;
    @apply dark:bg-zinc-800 dark:border-zinc-700 dark:placeholder:text-zinc-500;
  }

  .grid-lg,
  .grid-md,
  .grid-sm {
    @apply flex flex-row flex-wrap;
  }
  .grid-lg .grid-item {
    @apply w-full xs:w-1/2 sm-alt:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6 2xl:w-1/7 3xl:w-1/8 4xl:w-1/9;
    @apply p-[4vw] xs:p-[2vw] sm-alt:p-[1.3333vw] md:p-[1vw] lg:p-[0.8vw] xl:p-[0.6667vw] 2xl:p-[0.5714vw] 3xl:p-[0.5vw] 4xl:p-[0.4444vw];
  }
  .grid-md .grid-item {
    @apply w-1/2 xs:w-1/3 sm-alt:w-1/4 md:w-1/5 lg:w-1/6 xl:w-1/7 2xl:w-1/8 3xl:w-1/9 4xl:w-1/10;
    @apply p-[2vw] xs:p-[1.3333vw] sm-alt:p-[1vw] md:p-[0.8vw] lg:p-[0.6667vw] xl:p-[0.5714vw] 2xl:p-[0.5vw] 3xl:p-[0.4444vw] 4xl:p-[0.4vw];
  }
  .grid-sm .grid-item {
    @apply w-1/3 xs:w-1/4 sm-alt:w-1/5 md:w-1/6 lg:w-1/7 xl:w-1/8 2xl:w-1/9 3xl:w-1/10 4xl:w-1/11;
    @apply p-[1.3333vw] xs:p-[1vw] sm-alt:p-[0.8vw] md:p-[0.6667vw] lg:p-[0.5714vw] xl:p-[0.5vw] 2xl:p-[0.4444vw] 3xl:p-[0.4vw] 4xl:p-[0.3636vw];
  }
}
